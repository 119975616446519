import axios from "axios";
import moment from "moment";

const API_URL = `${process.env.REACT_APP_API_URL}/concorrencia`;

export const getBeneficios = async (token, documento) => {
  const url = `${API_URL}/tipo-beneficio?documento=${documento}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBeneficiarios = async (token, id, page, like) => {
  const url = `${API_URL}/beneficiario?conta_id=${id}&mostrar=10&page=${page}&documento=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteBeneficiario = async (token, userID) => {
  const url = `${API_URL}/beneficiario/${userID}`;

  return axios({
    method: "delete",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putUpdateBeneficiario = async (token, userID, data) => {
  // data: {
  //     "beneficiario": {
  //         "email": "",
  //         "documento": "",
  //         "nome": "",
  //         "celular": ""
  //     },
  //     "endereco": {
  //         "cep": "",
  //         "rua": "",
  //         "bairro": "",
  //         "numero": "",
  //         "complemento": "",
  //         "cidade": "",
  //         "estado": ""
  //     }
  // }
  const url = `${API_URL}/beneficiario/${userID}`;

  return axios({
    method: "put",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const postAddBeneficiario = async (token, tipo_beneficio_id, data) => {
  // data: {
  //     "beneficiario": {
  //         "nome": "",
  //         "email": "",
  //         "data_nascimento": "",
  //         "documento": "",
  //         "celular": ""
  //     },
  //     "endereco": {
  //         "cep": "",
  //         "rua": "",
  //         "bairro": "",
  //         "numero": "",
  //         "complemento": "",
  //         "cidade": "",
  //         "estado": ""
  //     }
  // }
  const url = `${API_URL}/beneficiario`;

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
      beneficiario: {
        ...data.beneficiario,
        data_nascimento: moment(
          data.beneficiario.data_nascimento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        tipo_beneficio_id,
      },
    },
  });
};

export const postAddLoteBeneficiarios = async (token, file) => {
  const url = `${API_URL}/beneficiario/arquivo-lote`;

  const fileForm = new FormData();
  fileForm.append("file", file);

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileForm,
  });
};

export const getTransacoes = async (token, page, like) => {
  const url = `${API_URL}/extrato?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTransacoesPre = async (token, cartao, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/conta/cartao-pre-pago-externo/${cartao}/history?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTransacaoBeneficiario = async (token, id, page, like) => {
  const url = `${API_URL}/extrato?user_id=${id}&mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCartoes = async (token, id, page, like) => {
  const url = `${API_URL}/beneficiario/cartoes-privados?conta_id=${id}&mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCartoesPre = async (token, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/conta/cartao-pre-pago?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCartao = async (token, cardID) => {
  const url = `${API_URL}/beneficiario/${cardID}/cartoes-privados`;

  return axios({
    method: "delete",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAddCartao = async (token, tipo_beneficio_id, data) => {
  //  data: {
  //    documento: "543.697.620-49",
  //    data_solicitacao: "2024-12-09"
  //  }
  const url = `${API_URL}/beneficiario/cartoes-privados`;

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tipo_beneficio_id,
      documento: data.documento,
      data_solicitacao: moment(data.data_solicitacao, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
    },
  });
};

export const postAddLoteCartoes = async (token, file) => {
  const url = `${API_URL}/beneficiario/cartoes-privados/arquivo-lote`;

  const fileForm = new FormData();
  fileForm.append("file", file);

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileForm,
  });
};

export const getVouchers = async (token, id, page, like) => {
  const url = `${API_URL}/beneficiario/contas?conta_id=${id}&mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteVoucher = async (token, cardID) => {
  const url = `${API_URL}/beneficiario/contas/${cardID}`;

  return axios({
    method: "delete",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAddVoucher = async (token, tipo_beneficio_id, data) => {
  //  data: {
  //    documento: "",
  //    chave_pix: ""
  //  }
  const url = `${API_URL}/beneficiario/contas`;

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tipo_beneficio_id,
      ...data,
    },
  });
};

export const postAddLoteVouchers = async (token, file) => {
  const url = `${API_URL}/beneficiario/contas/arquivo-lote`;

  const fileForm = new FormData();
  fileForm.append("file", file);

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileForm,
  });
};

export const getPagamentosVoucher = (token, id, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/concorrencia/pagamento-aluguel?conta_id=${id}&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPagamentosEstabelecimento = (token, id, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/concorrencia/pagamento-estabelecimento?conta_id=${id}&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPagamentosCartaoPrivado = (token, id, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/concorrencia/cartao-privado-pagamento?conta_id=${id}&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
