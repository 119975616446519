export const LOAD_CONTAS = "LOAD_CONTAS";
export const POST_LOGIN = "POST_LOGIN";
export const LOAD_CONTA_ID = "LOAD_CONTA_ID";
export const POST_CONTA = "POST_CONTA";
export const LOAD_BANCOS = "LOAD_BANCOS";
export const POST_CONTA_BANCARIA = "POST_CONTA_BANCARIA";
export const LOAD_CONTA_BANCARIA = "LOAD_CONTA_BANCARIA";
export const DEL_CONTA_BANCARIA = "DEL_CONTA_BANCARIA";
export const SET_STATE = "SET_STATE";
export const GET_APROVAR_CONTA = "GET_APROVAR_CONTA";
export const GET_REAPROVAR_CONTA = "GET_REAPROVAR_CONTA";
export const GET_FINALIZAR_CADASTRO_CONTA = "GET_FINALIZAR_CADASTRO_CONTA";
export const LOAD_DOCUMENTO = "LOAD_DOCUMENTO";
export const DELETE_DOCUMENTO = "DELETE_DOCUMENTO";
export const POST_DOCUMENTO = "POST_DOCUMENTO";
export const GET_ENVIAR_DOCUMENTO_IDWALL = "GET_ENVIAR_DOCUMENTO_IDWALL";
export const LOAD_ALL_CONTAS = "LOAD_ALL_CONTAS";
export const GET_RESUMO_CONTA_DASHBOARD = "GET_RESUMO_CONTA_DASHBOARD";
export const GET_GRAFICO_CONTA_LINE_DASHBOARD =
  "GET_GRAFICO_CONTA_LINE_DASHBOARD";
export const GET_GRAFICO_CONTA_BAR_DASHBOARD =
  "GET_GRAFICO_CONTA_BAR_DASHBOARD";
export const GET_CONTAS_EXPORT = "GET_CONTAS_EXPORT ";
export const POST_CAPTURA = "POST_CAPTURA";
export const LOAD_COBRANCAS_CARTAO = "LOAD_COBRANCAS_CARTAO";
export const POST_SPLIT = "POST_SPLIT";
export const LOAD_BOLETOS = "LOAD_BOLETOS";
export const LOAD_CARNE = "LOAD_CARNE";
export const LOAD_LINK_PAGAMENTOS = "LOAD_LINK_PAGAMENTOS";
export const LOAD_LANCAMENTOS_FUTUROS = "LOAD_LANCAMENTOS_FUTUROS";
export const LOAD_EXTRATO = "LOAD_EXTRATO";
export const LOAD_EXPORT_EXTRATO = "LOAD_EXPORT_EXTRATO";
export const LOAD_ASSINATURAS = "LOAD_ASSINATURAS";
export const LOAD_PLANOS = "LOAD_PLANOS";
export const LOAD_HISTORICO_TRANSACAO = "LOAD_HISTORICO_TRANSACAO";
export const LOAD_EXPORT_TRANSACAO = "LOAD_EXPORT_TRANSACAO";
export const LOAD_PAGADORES = "LOAD_PAGADORES";
export const DEL_PAGADOR = "DEL_PAGADOR";
export const GET_TRANSACAO_TED = "GET_TRANSACAO_TED";
export const UPDATE_USER_CONTA = "UPDATE_USER_CONTA";
export const GET_TRANSACAO_PIX = "GET_TRANSACAO_PIX";
export const GET_CHAVES_PIX = "GET_CHAVES_PIX";
export const LOAD_TRANSACAO = "LOAD_TRANSACAO";
export const CLEAR_TRANSACAO = "CLEAR_TRANSACAO";
export const LOAD_HISTORICO_TRANSFERENCIA = "LOAD_HISTORICO_TRANSFERENCIA";
export const LOAD_EXPORT_TRANSFERENCIA = "LOAD_EXPORT_TRANSFERENCIA";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const GET_LISTA_ADMINISTRADOR = "GET_LISTA_ADMINISTRADOR";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_REENVIAR_TOKEN_USUARIO = "GET_REENVIAR_TOKEN_USUARIO";
export const POST_PRIMEIRO_ACESSO = "POST_PRIMEIRO_ACESSO";
export const POST_RECUPERAR_SENHA = "POST_RECUPERAR_SENHA";
export const POST_SOLICITAR_RESET = "POST_SOLICITAR_RESET";
export const POST_EMAIL = " POST_EMAIL";
export const LOAD_PERFIL_TAXA = "LOAD_PERFIL_TAXA";
export const LOAD_PERFIL_TAXA_ID = "LOAD_PERFIL_TAXA_ID";
export const POST_PERFIL_TAXA = "POST_PERFIL_TAXA";
export const PUT_PERFIL_TAXA = "PUT_PERFIL_TAXA";
export const DELETE_PERFIL_TAXA = "DELETE_PERFIL_TAXA";
export const POST_VINCULAR_PERFIL_TAXA = "POST_VINCULAR_PERFIL_TAXA";
export const POST_USER_BLOQUEAR_DESBLOQUEAR = "POST_USER_BLOQUEAR_DESBLOQUEAR";
export const LOAD_PERMISSAO_GERENCIAR = "LOAD_PERMISSAO_GERENCIAR";
export const POST_PERMISSAO = "POST_PERMISSAO";
export const DEL_PERMISSAO = "DEL_PERMISSAO";
export const POST_AUTH_ME = "POST_AUTH_ME";
export const LOAD_PERMISSAO = "LOAD_PERMISSAO";
export const GET_LOGS = "GET_LOGS";
export const LOAD_LISTAR_PRODUTOS_GIFT_CARD = "LOAD_LISTAR_PRODUTOS_GIFT_CARD";
export const LOAD_DETALHES_GIFT_CARD = "LOAD_DETALHES_GIFT_CARD";
export const LOAD_LISTAR_RECARGAS = "LOAD_LISTAR_RECARGAS";
export const LOAD_DETALHES_RECARGA = "LOAD_DETALHES_RECARGA";
export const LOAD_LISTA_PRE_CONTAS = "LOAD_LISTA_PRE_CONTAS";
export const LOAD_LISTA_PRE_CONTA_ID = "LOAD_LISTA_PRE_CONTA_ID";
export const CLEAR_PRE_CONTA_ID = "CLEAR_PRE_CONTA_ID";
export const LOAD_TRANSFERENCIA_ID = "LOAD_TRANSFERENCIA_ID";
export const GET_TRANSACAO_TED_ID = "GET_TRANSACAO_TED_ID";
export const GET_TRANSACAO_PIX_ID = "GET_TRANSACAO_PIX_ID";
export const LOAD_PARTNER_TRANSACTIONS = "LOAD_PARTNER_TRANSACTIONS";
export const POST_BLOQUEAR_DEVICE = "POST_BLOQUEAR_DEVICE";
export const POST_DESBLOQUEAR_DEVICE = "POST_DESBLOQUEAR_DEVICE";
export const LOAD_LISTA_DEVICE_BLOQUEADO = "LOAD_LISTA_DEVICE_BLOQUEADO";
export const POST_DESBLOQUEAR_PERFIL_TAXA = "POST_DESBLOQUEAR_PERFIL_TAXA";
export const POST_BLACK_LIST_SELFIE = "POST_BLACK_LIST_SELFIE";
export const GET_BLACKLIST = "GET_BLACKLIST";
export const DEL_USER_REPRESENTANTE = "DEL_USER_REPRESENTANTE";
export const POST_USER_REPRESENTANTE = "POST_USER_REPRESENTANTE";
export const GET_FOLHA_DE_PAGAMENTO = "GET_FOLHA_DE_PAGAMENTO";
export const GET_LISTA_BANNER = "GET_LISTA_BANNER";
export const POST_BANNER = "POST_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const GET_FUNCIONARIO = "GET_FUNCIONARIO";
export const GET_CARTOES = "GET_CARTOES";
export const POST_CONFIRM_REQUEST_CARD = "POST_CONFIRM_REQUEST_CARD";
export const POST_CANCEL_CARD = "POST_CANCEL_CARD";
export const GET_TRANSFERENCIA_EXTRATO = "GET_TRANSFERENCIA_EXTRATO";
export const GET_TED_EXTRATO = "GET_TED_EXTRATO";
export const GET_PAGAMENTO_CONTA_EXTRATO = "GET_PAGAMENTO_CONTA_EXTRATO";
export const GET_PAGAMENTO_PIX_EXTRATO = "GET_PAGAMENTO_PIX_EXTRATO";
export const GET_CONTA_EMPRESA = "GET_CONTA_EMPRESA";
export const GET_FOLHA_PAGAMENTO_FUNCIONARIO =
  "GET_FOLHA_PAGAMENTO_FUNCIONARIO";
export const GET_CARTAO_EXPORT = "GET_CARTAO_EXPORT";
export const GET_PAGAMENTO_PIX = "GET_PAGAMENTO_PIX";
export const GET_TRANSFERENCIA_P2P = "GET_TRANSFERENCIA_P2P";
export const GET_TRANSFERENCIA_TED = "GET_TRANSFERENCIA_TED";
export const GET_PAGAMENTO_CONTA = "GET_PAGAMENTO_CONTA";
export const GET_SINCRONIZAR_CONTA = "GET_SINCRONIZAR_CONTA";
export const GET_REENVIAR_DOCUMENTO = "GET_REENVIAR_DOCUMENTO";
export const GET_PAGAMENTO_BOLETO = "GET_PAGAMENTO_BOLETO";
export const GET_ENVIAR_FITBANK = "GET_ENVIAR_FITBANK";
export const GET_SINCRONIZAR_EXTRATO = "GET_SINCRONIZAR_EXTRATO";
export const SET_SESSION_AUTH = "SET_SESSION_AUTH";
export const POST_CRIAR_SELLER_ZOOP = "POST_CRIAR_SELLER_ZOOP";
export const POST_RECUSAR_SELLER_ZOOP = "POST_RECUSAR_SELLER_ZOOP";
export const POST_NOTIFICACAO = "POST_NOTIFICACAO";
export const GET_TERMINAIS_POS = "GET_TERMINAIS_POS";
export const GET_EXPORTACOES_SOLICITADAS = "GET_EXPORTACOES_SOLICITADAS";
export const GET_EXPORT_DOWNLOAD = "GET_EXPORT_DOWNLOAD";
export const GET_MINHAS_ASSINATURAS = "GET_MINHAS_ASSINATURAS";
export const GET_MINHAS_TAXAS = "GET_MINHAS_TAXAS";
export const GET_EXTRATO_ADQUIRENCIA = "GET_EXTRATO_ADQUIRENCIA";
export const GET_PLANO_VENDAS = "GET_PLANO_VENDAS";
export const GET_PLANO_VENDAS_ID = "GET_PLANO_VENDAS_ID";
export const GET_PLANO_VENDAS_ZOOP = "GET_PLANO_VENDAS_ZOOP";
export const POST_ASSINATURA_PLANO_VENDAS = "POST_ASSINATURA_PLANO_VENDAS";
export const DEL_ASSINATURA_PLANO_VENDAS = "DEL_ASSINATURA_PLANO_VENDAS";
export const POST_CRIAR_TAXAS_PADRAO = "POST_CRIAR_TAXAS_PADRAO";
export const POST_SET_PLANO_PADRAO = "POST_SET_PLANO_PADRAO";
export const DEL_PLANO_VENDAS = "DEL_PLANO_VENDAS";
export const POST_SET_CONTA_PADRAO = "POST_SET_CONTA_PADRAO";
export const GET_CONTA_PADRAO = "GET_CONTA_PADRAO";
export const POST_TERMINAL_POS = "POST_TERMINAL_POS";
export const GET_PLANO_VENDAS_ZOOP_ID = "GET_PLANO_VENDAS_ZOOP_ID";
export const POST_PLANO_VENDAS_ZOOP = "POST_PLANO_VENDAS_ZOOP";
export const GET_TERMINAL_POS = "GET_TERMINAL_POS";
export const DEL_TERMINAL_POS = "DEL_TERMINAL_POS";
export const GET_TERMINAL_POS_TRANSACTIONS = "GET_TERMINAL_POS_TRANSACTIONS";
export const PUT_TERMINAL_POS = "PUT_TERMINAL_POS";
export const POST_CANCELAR_TRANSACAO = "POST_CANCELAR_TRANSACAO";
export const GET_REPRESENTANTES = "GET_REPRESENTANTES";
export const POST_IMPORTAR_REPRESENTANTE = "POST_IMPORTAR_REPRESENTANTE";
export const POST_CRIAR_REPRESENTANTE = "POST_CRIAR_REPRESENTANTE";
export const GET_ASSINATURA_PLANO_VENDAS = "GET_ASSINATURA_PLANO_VENDAS";
export const GET_MEUS_ECS = "GET_MEUS_ECS";
export const PUT_FEES = "PUT_FEES";
export const POST_CONTA_FISICA_ZOOP = "POST_CONTA_FISICA_ZOOP";
export const POST_CONTA_JURIDICA_ZOOP = "POST_CONTA_JURIDICA_ZOOP";
export const GET_REENVIAR_DOCUMENTO_SOCIO = "GET_REENVIAR_DOCUMENTO_SOCIO";
export const LOAD_PERFIL_TAXA_PADRAO = "LOAD_PERFIL_TAXA_PADRAO";
export const LOAD_PERFIL_TAXA_PADRAO_ID = "LOAD_PERFIL_TAXA_PADRAO_ID";
export const POST_PERFIL_TAXA_PADRAO = "POST_PERFIL_TAXA_PADRAO";
export const PUT_PERFIL_TAXA_PADRAO = "PUT_PERFIL_TAXA_PADRAO";
export const DELETE_PERFIL_TAXA_PADRAO = "DELETE_PERFIL_TAXA_PADRAO";
export const GET_TRANSACAO_TARIFAS = "GET_TRANSACAO_TARIFAS";
