var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
Object.defineProperty(exports, "Query", {
  enumerable: true,
  get: function get() {
    return _Query["default"];
  },
});

var _Query = _interopRequireDefault(require("./Query"));
